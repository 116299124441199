import * as msal from '@azure/msal-browser';
import type {ReactElement, FC} from 'react';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import Loading from '../Loading';
import AuthError from './AuthError';
import {absoluteUrl} from './Uri';

if (!import.meta.env.VITE_APP_AAD_TENANT_ID) {
    throw new Error('VITE_APP_AAD_TENANT_ID not defined');
}

if (!import.meta.env.VITE_APP_AAD_CLIENT_ID) {
    throw new Error('VITE_APP_AAD_CLIENT_ID not defined');
}

if (!import.meta.env.VITE_APP_AAD_SCOPE) {
    throw new Error('VITE_APP_AAD_SCOPE not defined');
}

const apiScope = import.meta.env.VITE_APP_AAD_SCOPE;

export const msalClient = new msal.PublicClientApplication({
    auth: {
        authority: `https://login.microsoftonline.com/${import.meta.env.VITE_APP_AAD_TENANT_ID}`,
        clientId: import.meta.env.VITE_APP_AAD_CLIENT_ID,
        redirectUri: absoluteUrl('/').toString(),
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
});

const loginScopes = [
    'openid',
    apiScope,
];

type Props = {
    children : ReactElement;
};

const msalContext = createContext<msal.AccountInfo | null>(null);

const MsalProvider : FC<Props> = ({children} : Props) => {
    const [account, setAccount] = useState<msal.AccountInfo | null>(null);
    const [authError, setAuthError] = useState(false);

    useEffect(() => {
        void (async () => {
            await msalClient.initialize();

            // Step 1: Check URL for redirect response
            let tokenResponse : msal.AuthenticationResult | null = null;

            try {
                tokenResponse = await msalClient.handleRedirectPromise();
            } catch (e) {
                setAuthError(true);
                return;
            }

            if (tokenResponse !== null) {
                setAccount(tokenResponse.account);
                return;
            }

            // Step 2: Check cached data
            const currentAccounts = msalClient.getAllAccounts();

            if (currentAccounts.length > 0) {
                setAccount(currentAccounts[0]);
                return;
            }

            // Step 3: No signed in user, redirect to login
            return msalClient.loginRedirect({scopes: loginScopes});
        })();
    }, []);

    if (authError) {
        return <AuthError login={async () => msalClient.loginRedirect({scopes: loginScopes})}/>;
    }

    if (!account) {
        return <Loading/>;
    }

    return (
        <msalContext.Provider value={account}>
            {children}
        </msalContext.Provider>
    );
};

type Msal = {
    account : msal.AccountInfo;
    logout : () => void;
    getAccessToken : (scope ?: string) => Promise<string>;
};

export const useMsal = () : Msal => {
    const account = useContext(msalContext);

    if (!account) {
        throw new Error('Context used before initialization');
    }

    return useMemo(() => ({
        account: account,
        logout: async () => msalClient.logoutRedirect(),
        getAccessToken: async (scope = apiScope) => {
            const scopes = [scope];

            try {
                return (await msalClient.acquireTokenSilent({
                    account,
                    scopes,
                    redirectUri: absoluteUrl('/silent.html').toString(),
                })).accessToken;
            } catch (e) {
                if (e instanceof msal.InteractionRequiredAuthError) {
                    await msalClient.acquireTokenRedirect({account, scopes});
                    return '';
                }
            }

            await msalClient.loginRedirect({scopes});
            return '';
        },
    }), [account]);
};

export default MsalProvider;
