import {Container, Nav} from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import {Outlet} from 'react-router-dom';

const Layout = () : JSX.Element => {
    return (
        <>
            <header>
                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Collapse id="basic-navbar-nav" role="menubar">
                            <Nav className="me-auto">
                                <Nav.Link href="/software">Software</Nav.Link>
                                <Nav.Link href="/devices">Devices</Nav.Link>
                                <Nav.Link href="/notes">Notes</Nav.Link>
                                <Nav.Link href="/users">Users</Nav.Link>
                                <Nav.Link href="/macversions">Mac Versions</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            <main>
                <Outlet/>
            </main>
        </>
    );
};

export default Layout;
