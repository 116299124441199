import type {FC} from 'react';
import {Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

type Props = {
    login : () => void;
};

const AuthError : FC<Props> = ({login} : Props) => {
    return (
        <Modal.Dialog>
            <Modal.Header closeButton>
                <Modal.Title>Login Error</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>An error occurred during authentication, please try again!</p>
            </Modal.Body>

            <Modal.Footer>
                <Button color="primary" onClick={login}>
                    Sign in
                </Button>
            </Modal.Footer>
        </Modal.Dialog>
    );
};

export default AuthError;
