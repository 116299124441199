import {Card, Col, Container, Row} from 'react-bootstrap';
import {useQuery} from 'react-query';
import VersionForm from '@/components/VersionForm';
import useApiFetch from '@/Utils/useApiFetch';

export type MacVersionsApi = {
    id : string;
    majorVersion : number;
    minVersion : string;
    enforceDate : string;
};

const MacVersions = () : JSX.Element => {
    const apiFetch = useApiFetch();

    const {isLoading: versionsLoading, error: versionsError, data: macVersions, refetch} = useQuery<MacVersionsApi[]>(
        'macVersions',
        async () => {
            const res = await apiFetch('/v1/macos-versions');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await res.json();
        }
    );

    if (versionsError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching the devices {JSON.stringify(versionsError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (versionsLoading || !macVersions?.length) {
        return <>
            <Container>
                <Row>
                    <Col className="mx-auto">
                        <h1>Loading</h1>
                        <div className="spinner-border" role="status">
                        </div>
                    </Col>
                </Row>
            </Container>
        </>;
    }

    return (
        <>
            <Container>
                {macVersions.map(version => {
                    return (
                        <VersionForm
                            refresh={async () => {
                                await refetch();
                            }}
                            key={version.id}
                            version={version}
                        />);
                })}
            </Container>
        </>
    );
};

export default MacVersions;
