import {useCallback} from 'react';
import {useMsal} from '@/components/Auth/Msal';

if (!import.meta.env.VITE_APP_API_ENDPOINT) {
    throw new Error('REACT_APP_API_ENDPOINT not defined');
}

export const apiEndpoint : string = import.meta.env.VITE_APP_API_ENDPOINT;

export type ApiFetch = (url : string, init ?: RequestInit) => Promise<Response>;

let accessTokenPromise : Promise<string> | null = null;

const useApiFetch = () : ApiFetch => {
    const msal = useMsal();

    return useCallback(async (url : string, init ?: RequestInit) => {
        let accessToken : string;

        if (!accessTokenPromise) {
            accessTokenPromise = msal.getAccessToken();
            accessToken = await accessTokenPromise;
            accessTokenPromise = null;
        } else {
            accessToken = await accessTokenPromise;
        }

        if (!init) {
            init = {};
        }

        init.headers = init.headers instanceof Headers ? init.headers : new Headers(init.headers);
        init.headers.append('Authorization', `Bearer ${accessToken}`);
        init.headers.append('Content-Type', 'application/json');

        return fetch(new URL(url, apiEndpoint).toString(), init);
    }, [msal]);
};

export default useApiFetch;
