import {Client as GraphClient} from '@microsoft/microsoft-graph-client';
import {useEffect, useMemo, useState} from 'react';
import {Card, Col, Container, FormLabel, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import type {TableColumn} from 'react-data-table-component';
import DataTable from 'react-data-table-component';
import {useQuery} from 'react-query';
import {useMsal} from '@/components/Auth/Msal';
import useApiFetch from '@/Utils/useApiFetch';

type CrashPlanUser = {
    userId : number;
    userUid : string;
    status : string;
    username : string;
    email : string;
    firstName : string;
    lastName : string;
    quotaInBytes : number;
    orgId : number;
    orgUid : string;
    orgGuid : string;
    orgName : string;
    active : boolean;
    blocked : boolean;
    invited : boolean;
    creationDate : string;
    modificationDate : string;
    computerCount : number;
    backupDeviceCount : number;
};

type TrackerUser = {
    associateID : string;
    accountName : string;
    nameFirst : string;
    nameLast : string;
    emailWork : string;
    associateStatus : string;
    associateType : string;
};

type ListDevice = {
    'deviceId' : string;
    'deviceName' : string;
    'model' : string;
    'serialNumber' : string;
    'platform' : 'Mac' | 'iPad' | 'iPhone' | 'AppleTV';
    'osVersion' : string;
    'lastCheckIn' : string;
    'assetTag' : string;
    'blueprintId' : string;
    'mdmEnabled' : boolean;
    'agentInstalled' : boolean;
    'isMissing' : boolean;
    'isRemoved' : boolean;
    'agentVersion' : string;
    'firstEnrollment' : string;
    'lastEnrollment' : string;
    'blueprintName' : string;
    user ?: {
        email : string;
        name : string;
        id : number;
        isArchived : boolean;
    };
};

type AADUser = {
    mail : string;
    active : boolean;
    license : string;
    id : string;
};

export type RingCentralUser = {
    'id' : number;
    'extensionNumber' : string;
    'email' : string;
};

type User = {
    mail : string;
    tracker ?: TrackerUser;
    crashPlan ?: CrashPlanUser;
    device ?: ListDevice;
    aadUser ?: AADUser;
    rc ?: RingCentralUser;
};

const businessPremium = 'f245ecc8-75af-4f8e-b61f-27d8114de5f3';
const businessEssentials = '3b555118-da6a-4418-894f-7df1e2096870';
const eThree = '6fd2c87f-b296-42f0-b197-1e91e994b900';

const columns : Array<TableColumn<User>> = [
    {
        name: 'User',
        selector: (row : User) => (row.mail),
        sortable: true,
    },
    {
        name: 'Active Tracker Record',
        selector: (row : User) => row.tracker?.emailWork ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Tracker Type',
        selector: (row : User) => row.tracker?.associateType ?? '',
        sortable: true,
        conditionalCellStyles: [{
            when: r => {
                return r.tracker?.associateType === 'Employee';
            },
            classNames: ['table-success'],
        }, {
            when: r => {
                return r.tracker?.associateType === 'Subcontractor';
            },
            classNames: ['table-warning'],
        }, {
            when: r => {
                return r.tracker?.associateType === undefined;
            },
            classNames: ['table-danger'],
        }],
    },
    {
        name: 'Crashplan Account',
        selector: (row : User) => row.crashPlan?.email ? 'Yes' : 'No',
        format: row => {
            if (!row.crashPlan) {
                return 'No';
            }

            return <a
                target="_blank"
                href={`https://console.us2.crashplan.com/app/#/console/user/${row.crashPlan.userId}`}
                rel="noreferrer">
                Yes
            </a>;
        },
        sortable: true,
    },
    {
        name: 'Crashplan Computers',
        selector: (row : User) => row.crashPlan?.backupDeviceCount ?? '',
        sortable: true,
    },
    {
        name: 'Kandji Computer',
        selector: (row : User) => row.device?.deviceName ?? '',
        format: row => {
            if (!row.device) {
                return '';
            }

            return <a
                target="_blank"
                href={`https://soliantconsulting.kandji.io/devices/${row.device.deviceId}`}
                rel="noreferrer">
                {row.device.deviceName}
            </a>;
        },
        sortable: true,
    },
    {
        name: 'Azure License',
        selector: (row : User) => row.aadUser?.license ?? '',
        format: row => {
            if (!row.aadUser) {
                return '';
            }

            return <a
                target="_blank"
                href={`https://admin.microsoft.com/#/users/:/UserDetails/${row.aadUser.id}/LicensesAndApps`}
                rel="noreferrer">
                {row.aadUser.license}
            </a>;
        },
        sortable: true,
    },
    {
        name: 'RC Ext',
        selector: (row : User) => row.rc?.extensionNumber ?? '',
        format: row => {
            if (!row.rc) {
                return '';
            }

            return <a
                target="_blank"
                href={`https://service.ringcentral.com/application/users/users/default/${row.rc.id}/settings/default`}
                rel="noreferrer">
                {row.rc.extensionNumber}
            </a>;
        },
        sortable: true,
    },
];

const ALL = 'All';
const HAS = 'Has';
const HAS_NOT = 'Has_not';

const filterComputers = (
    users : User[],
    trackerFilter : string | undefined,
    filterKandji : string | undefined,
    filterCrashplan : string | undefined,
    filterRC : string | undefined,
) : User[] => {
    return users.filter(u => {
        if (trackerFilter !== ALL) {
            if (trackerFilter === '-1' && u.tracker?.associateType !== undefined
                || trackerFilter !== '-1' && u.tracker?.associateType !== trackerFilter) {
                return false;
            }
        }

        if (filterKandji !== ALL) {
            if (filterKandji === HAS && u.device === undefined
                || filterKandji === HAS_NOT && u.device !== undefined) {
                return false;
            }
        }

        if (filterCrashplan !== ALL) {
            if (filterCrashplan === HAS && u.crashPlan === undefined
                || filterCrashplan === HAS_NOT && u.crashPlan !== undefined) {
                return false;
            }
        }

        if (filterRC !== ALL) {
            if (filterRC === HAS && u.rc?.extensionNumber === undefined
                || filterRC === HAS_NOT && u.rc?.extensionNumber !== undefined) {
                return false;
            }
        }

        return true;
    });
};

const Software = () : JSX.Element => {
    const msal = useMsal();
    const apiFetch = useApiFetch();
    const [filterCrashplan, setFilterCrashplan] = useState<string | undefined>(ALL);
    const [filterKandji, setFilterKandji] = useState<string | undefined>(ALL);
    const [filterRC, setFilterRC] = useState<string | undefined>(ALL);
    const [users, setUsers] = useState<User[]>([]);
    const [aadUsers, setAadUsers] = useState<AADUser[]>([]);
    const [aadError, setAADError] = useState<string | null>(null);
    const [trackerFilter, setTrackerFilter] = useState<string | undefined>(ALL);

    const graphClient = useMemo(() => GraphClient.initWithMiddleware({
        authProvider: {
            getAccessToken: async () => msal.getAccessToken('https://graph.microsoft.com/User.Read'),
        },
    }), [msal]);

    useEffect(() => {
        void (async () => {
            try {
                /* eslint @typescript-eslint/naming-convention: 0 */
                /* eslint @typescript-eslint/no-unsafe-assignment: 0 */
                /* eslint @typescript-eslint/no-unsafe-call: 0 */
                /* eslint @typescript-eslint/no-unsafe-member-access: 0 */
                /* eslint @typescript-eslint/no-explicit-any: 0 */
                const apiResponse = await graphClient
                    .api('users?$filter=assignedLicenses/$count ne 0&$count=true&$select=accountEnabled,'
                        + 'mail,assignedLicenses,id&$top=999')
                    .header('ConsistencyLevel', 'eventual')
                    .get();

                const rawUsers = apiResponse.value;

                if (Array.isArray(rawUsers)) {
                    const mappedUsers = rawUsers.map((u : any) : AADUser => {
                        const license = u.assignedLicenses.find((l : any) => l.skuId === eThree)
                            ? 'Office 365 E3'
                            : u.assignedLicenses.find((l : any) => l.skuId === businessPremium)
                                ? 'Business Standard'
                                : u.assignedLicenses.find((l : any) => l.skuId === businessEssentials)
                                    ? 'Business Basic'
                                    : 'unknown';
                        return {
                            active: u.accountEnabled,
                            license: license,
                            mail: u.mail.toLowerCase(),
                            id: u.id,
                        };
                    });
                    setAadUsers(mappedUsers);
                    setAADError(null);
                } else {
                    setAADError('error getting aad users');
                }
            } catch (e) {
                // User has no photo
                console.log('graph error', e);
                setAADError('error getting aad users');
                return;
            }
        })();
    }, [graphClient]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {isLoading, error: crashPlanError, data: crashPlanUsers}
        = useQuery<CrashPlanUser[]>('crashplan-users', async () => {
            const res = await apiFetch('/v1/crashplan-users');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await res.json();
        });
    const {isLoading: fmLoading, error: fmError, data: fmUsers}
        = useQuery<TrackerUser[]>('fm-users', async () => {
            const res = await apiFetch('/v1/filemaker-users');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await res.json();
        });
    const {isLoading: devicesLoading, error: devicesError, data: devices}
        = useQuery<ListDevice[]>(
            'deviceData',
            async () => {
                const res = await apiFetch('/v1/devices');
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return await res.json();
            }
        );
    const {isLoading: rcLoading, error: rcError, data: rcUsers} = useQuery<RingCentralUser[]>(
        'rc-users',
        async () => {
            return [];
        }
    );

    useEffect(() => {
        if (!crashPlanUsers || !fmUsers || !devices || !rcUsers) {
            return;
        }

        const tmpUsers : User[] = [];

        for (const user of crashPlanUsers) {
            tmpUsers.push({
                mail: user.email,
                tracker: fmUsers.find(fmUser => fmUser.emailWork === user.email),
                device: devices.find(device => device.user?.email === user.email),
                aadUser: aadUsers.find(adu => adu.mail === user.email),
                rc: rcUsers.find(rc => rc.email === user.email),
                crashPlan: user,
            });
        }

        for (const user of fmUsers) {
            if (!tmpUsers.find(t => t.mail === user.emailWork)) {
                tmpUsers.push({
                    mail: user.emailWork,
                    tracker: user,
                    device: devices.find(device => device.user?.email === user.emailWork),
                    aadUser: aadUsers.find(adu => adu.mail === user.emailWork),
                    rc: rcUsers.find(rc => rc.email === user.emailWork),
                });
            }
        }

        for (const user of aadUsers) {
            if (
                !tmpUsers.find(t => t.mail === user.mail)
            ) {
                tmpUsers.push({
                    mail: user.mail,
                    aadUser: user,
                    rc: rcUsers.find(rc => rc.email === user.mail),
                });
            }
        }

        for (const user of rcUsers) {
            if (
                !tmpUsers.find(t => t.mail === user.email)
            ) {
                tmpUsers.push({
                    mail: user.email,
                    rc: user,
                });
            }
        }

        setUsers(tmpUsers);
    }, [crashPlanUsers, fmUsers, aadUsers, rcUsers]);

    if (crashPlanError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching the crashplan users {JSON.stringify(crashPlanError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (fmError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching the employess from tracker {JSON.stringify(fmError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (devicesError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching kandji devices {JSON.stringify(devicesError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (aadError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching kandji devices {JSON.stringify(devicesError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (rcError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching Ringcentral users {JSON.stringify(rcError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (isLoading || fmLoading || devicesLoading || rcLoading) {
        return <>
            <Container>
                <Row>
                    <Col className="mx-auto">
                        <h1>Loading</h1>
                        <div className="spinner-border" role="status">
                        </div>
                    </Col>
                </Row>
            </Container>
        </>;
    }

    return (
        <>
            <Container fluid={true}>
                <Card className="mt-2">
                    <div className="card-body">
                        <h4>User List</h4>
                        <Row>
                            <Col xs m={4}>
                                <FormLabel htmlFor="app">Tracker Status</FormLabel>
                                <Form.Control
                                    type='select'
                                    as="select"
                                    name='app'
                                    id='app'
                                    className='mb-2'
                                    value={trackerFilter}
                                    onChange={event => {
                                        setTrackerFilter(event.target.value);
                                    }}
                                >
                                    <option value="All">All</option>
                                    <option value="Employee">Employee</option>
                                    <option value="Subcontractor">Subcontractor</option>
                                    <option value="-1">Not in Tracker</option>
                                </Form.Control>
                            </Col>
                            <Col xs m={4}>
                                <FormLabel htmlFor="app">CrashPlan Account</FormLabel>
                                <Form.Control
                                    type='select'
                                    as="select"
                                    name='app'
                                    id='app'
                                    className='mb-2'
                                    value={filterCrashplan}
                                    onChange={event => {
                                        setFilterCrashplan(event.target.value);
                                    }}
                                >
                                    <option value={ALL}>All</option>
                                    <option value={HAS}>Has</option>
                                    <option value={HAS_NOT}>Does not have</option>
                                </Form.Control>
                            </Col>
                            <Col xs m={4}>
                                <FormLabel htmlFor="app">Kandji Linked Computer</FormLabel>
                                <Form.Control
                                    type='select'
                                    as="select"
                                    name='app'
                                    id='app'
                                    className='mb-2'
                                    value={filterKandji}
                                    onChange={event => {
                                        setFilterKandji(event.target.value);
                                    }}
                                >
                                    <option value={ALL}>All</option>
                                    <option value={HAS}>Has</option>
                                    <option value={HAS_NOT}>Does not have</option>
                                </Form.Control>
                            </Col>
                            <Col xs m={4}>
                                <FormLabel htmlFor="app">Ringcentral</FormLabel>
                                <Form.Control
                                    type='select'
                                    as="select"
                                    name='app'
                                    id='app'
                                    className='mb-2'
                                    value={filterRC}
                                    onChange={event => {
                                        setFilterRC(event.target.value);
                                    }}
                                >
                                    <option value={ALL}>All</option>
                                    <option value={HAS}>Has</option>
                                    <option value={HAS_NOT}>Does not have</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <DataTable
                            columns={columns}
                            data={filterComputers(users, trackerFilter, filterKandji, filterCrashplan, filterRC)}
                        />
                    </div>
                </Card>
            </Container>
        </>
    );
};

export default Software;
