import type {FC} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

const Loading : FC = () => {
    return (
        <Container>
            <Row>
                <Col className="mx-auto">
                    <h1>Loading</h1>
                    <div className="spinner-border" role="status">
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Loading;
