import {Card, Col, Container, Row} from 'react-bootstrap';
import {useQuery} from 'react-query';
import useApiFetch from '@/Utils/useApiFetch';

type Props = {
    deviceId : string;
};

type DeviceNote = {
    author : string;
    content : string;
    noteId : string;
    createdAt : string;
    updatedAt : string;
    deviceId : string;
};

const DeviceNotes = ({deviceId} : Props) : JSX.Element => {
    const apiFetch = useApiFetch();

    const {isLoading: devicesLoading, error: devicesError, data: notes} = useQuery<DeviceNote[]>(
        ['device-note', deviceId],
        async () => {
            const res = await apiFetch(`/v1/devices/notes/${deviceId}`);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await res.json();
        }
    );

    if (devicesError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching the devices {JSON.stringify(devicesError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (devicesLoading || !notes) {
        return <>
            <Container>
                <Row>
                    <Col className="mx-auto">
                        <h1>Loading</h1>
                        <div className="spinner-border" role="status">
                        </div>
                    </Col>
                </Row>
            </Container>
        </>;
    }

    return (
        <>
            {notes.map((n, i) => {
                return (<div key={`device-notes-${n.noteId}`}>
                    <Card className={i % 2 === 0 ? 'bg-light mt-2' : 'mt-2'}>
                        <Card.Body>
                            <h4>Note By {n.author} created {new Date(n.createdAt).toLocaleString()}</h4>
                            <p key={n.noteId}
                                dangerouslySetInnerHTML={{__html: n.content}}
                            />
                        </Card.Body>
                    </Card>
                </div>);
            })}
        </>
    );
};

export default DeviceNotes;
