import {compareVersions} from 'compare-versions';
import {useEffect, useState} from 'react';
import {Card, Col, Container, Row, FormGroup, FormLabel} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DataTable from 'react-data-table-component';
import type {TableColumn} from 'react-data-table-component';
import {useQuery} from 'react-query';
import useApiFetch from '@/Utils/useApiFetch';

type InstalledApp = {
    'appName' : string;
    'bundleId' : string;
    'appStoreVendable' : string;
    'deviceBasedVpp' : string;
    'source' : string;
    'process' : string;
    'signature' : string;
    appInstalls : AppInstall[];
};

type AppInstall = {
    deviceId : string;
    path : string;
    version : string;
};

type ListDevice = {
    'deviceId' : string;
    'deviceName' : string;
    'model' : string;
    'serialNumber' : string;
    'platform' : 'Mac' | 'iPad' | 'iPhone' | 'AppleTV';
    'osVersion' : string;
    'lastCheckIn' : string;
    'user' : {
        email ?: string;
        name ?: string;
        id ?: number;
        isArchived ?: boolean;
    };
    'assetTag' : string;
    'blueprintId' : string;
    'mdmEnabled' : boolean;
    'agentInstalled' : boolean;
    'isMissing' : boolean;
    'isRemoved' : boolean;
    'agentVersion' : string;
    'firstEnrollment' : string;
    'lastEnrollment' : string;
    'blueprintName' : string;
};

type SortedInstall = {
    install : AppInstall | undefined;
    device : ListDevice;
};

const techStaffApps = [
    'com.filemaker.client.pro12',
    'com.google.Chrome',
    'com.fortinet.FortiClient',
    'com.microsoft.Excel',
    'com.microsoft.onenote.mac',
    'com.microsoft.Outlook',
    'com.microsoft.Powerpoint',
    'com.microsoft.rdc.macos',
    'com.microsoft.teams',
    'com.microsoft.Word',
    'com.avast.AACM',
    'com.lastpass.LastPass',
    'com.crashplan.desktop',
    'us.zoom.xos',
    'org.virtualbox.app.VirtualBox',
    'com.vmware.fusion',
    'com.parallels.desktop.console',
];

const renderVersions = (versionCount : Map<string, number>) : JSX.Element => {
    const items : JSX.Element[] = [];

    for (const version of versionCount.keys()) {
        items.push(<Row key={'installCnt-' + version}><Col>
            {version} - {versionCount.get(version)}
        </Col></Row>);
    }

    return <>{items}</>;
};

const columns : Array<TableColumn<SortedInstall>> = [
    {
        name: 'Version',
        selector: (row : SortedInstall) => row.install?.version ?? 'not installed',
        sortable: true,
        sortFunction: (a, b) : number => {
            if (!a.install) {
                return 1;
            }

            if (!b.install) {
                return -1;
            }

            return compareVersions(a.install.version.split(' ')[0], b.install.version.split(' ')[0]);
        },
    },
    {
        name: 'Device Name',
        selector: (row : SortedInstall) => row.device.deviceName,
        sortable: true,
    },
    {
        name: 'OS Version',
        selector: (row : SortedInstall) => row.device.osVersion,
        sortable: true,
        sortFunction: (a, b) => {
            return compareVersions(a.device.osVersion, b.device.osVersion);
        },
    },
    {
        name: 'Serial',
        cell: (row : SortedInstall) => <a target="_blank" href={`https://soliantconsulting.kandji.io/devices/${row.device.deviceId}/application-inventory?page=1&sizePerPage=300`} rel="noreferrer">
            {row.device.serialNumber}
        </a>,
        sortFunction: (a, b) => {
            return a.device.serialNumber.localeCompare(b.device.serialNumber);
        },
        sortable: true,
    },
    {
        name: 'Last Device Checkin',
        selector: (row : SortedInstall) => (new Date(row.device.lastCheckIn)).toLocaleString(),
        sortable: true,
    },
    {
        name: 'User',
        selector: (row : SortedInstall) => (row.device.user.email ?? '').split('@')[0],
        sortable: true,
    },
];

const Software = () : JSX.Element => {
    const apiFetch = useApiFetch();
    const [filtered, setFiltered] = useState(true);
    const [selectedAppBundle, setSelectedAppBundle] = useState<string>('');
    const [filteredApps, setFilteredApps] = useState<InstalledApp[]>([]);
    const [selectedApp, setSelectedApp] = useState<InstalledApp | undefined>(undefined);
    const [sortedDevices, setSortedDevices] = useState<SortedInstall[]>([]);
    const [versionCount, setVersionCount] = useState(new Map<string, number>());
    const {isLoading, error: appsError, data: apps} = useQuery<InstalledApp[]>('softwareData', async () => {
        const res = await apiFetch('/v1/software');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return await res.json();
    });

    const {isLoading: devicesLoading, error: devicesError, data: devices} = useQuery<ListDevice[]>(
        'deviceData',
        async () => {
            const res = await apiFetch('/v1/devices');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await res.json();
        }
    );

    useEffect(() => {
        if (apps?.length === undefined || apps.length === 0) {
            setFilteredApps([]);
            return;
        }

        const tmpFilteredApps = filtered
            ? apps.filter(a => techStaffApps.includes(a.bundleId))
            : apps;

        if (tmpFilteredApps.length > 0) {
            setSelectedAppBundle(tmpFilteredApps[0].bundleId);
        }

        setFilteredApps(tmpFilteredApps);
    }, [apps, filtered]);

    useEffect(() => {
        const app = apps?.find(a => a.bundleId === selectedAppBundle);
        setSelectedApp(app);

        if (app && devices) {
            const versionCount = new Map<string, number>();
            const sortedDevices = devices.map((device) : SortedInstall => {
                const install = app.appInstalls.find(i => i.deviceId === device.deviceId);

                if (install) {
                    const cnt = versionCount.get(install.version) ?? 0;
                    versionCount.set(install.version, cnt + 1);
                }

                return {
                    install: install,
                    device,
                };
            });
            sortedDevices.sort((a, b) => {
                if (!a.install) {
                    return 1;
                }

                if (!b.install) {
                    return -1;
                }

                return -1 * compareVersions(a.install.version.split(' ')[0], b.install.version.split(' ')[0]);
            });
            setSortedDevices(sortedDevices);
            setVersionCount(versionCount);
        } else {
            setSortedDevices([]);
        }
    }, [selectedAppBundle]);

    if (appsError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching the apps {JSON.stringify(appsError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (devicesError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching the devices {JSON.stringify(devicesError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (isLoading || !apps || devicesLoading || !devices) {
        return <>
            <Container>
                <Row>
                    <Col className="mx-auto">
                        <h1>Loading</h1>
                        <div className="spinner-border" role="status">
                        </div>
                    </Col>
                </Row>
            </Container>
        </>;
    }

    return (
        <>
            <Container>
                <Row className="mt-4 mb-3 py-4 px-2">
                    <Col>
                        <FormGroup>
                            <Form.Check
                                type='checkbox'
                                name='filterApps'
                                id='filterApps'
                                label="Only It Apps"
                                checked={filtered}
                                onChange={() => {
                                    setFiltered(!filtered);
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="app">Select App</FormLabel>
                            <Form.Control
                                type='select'
                                as="select"
                                name='app'
                                id='app'
                                value={selectedAppBundle}
                                onChange={event => {
                                    setSelectedAppBundle(event.target.value);
                                }}
                            >
                                {filteredApps.map(a => {
                                    return <option
                                        value={a.bundleId}
                                        key={a.bundleId}
                                    >{a.appName} - {a.bundleId} {a.appInstalls.length}</option>;
                                })}
                            </Form.Control>
                        </FormGroup>
                    </Col>
                </Row>
                {selectedApp && (
                    <Card>
                        <div className="card-body">
                            <Row><Col>
                                <h4>App Details</h4>
                                App Name: {selectedApp.appName}
                            </Col></Row>
                            <Row><Col>
                                App bundle: {selectedApp.bundleId}
                            </Col></Row>
                            <Row><Col>
                                App process: {selectedApp.process}
                            </Col></Row>
                            <Row><Col>
                                App source: {selectedApp.source}
                            </Col></Row>
                        </div>
                    </Card>
                )}
                <Card className="mt-2">
                    <div className="card-body">
                        <Row>
                            <Col>
                                <h4>Version Install Counts</h4>
                                {versionCount.size && renderVersions(versionCount)}
                            </Col>
                        </Row>
                    </div>
                </Card>
                <Card className="mt-2">
                    <div className="card-body">
                        <h4>Install List</h4>
                        <DataTable
                            columns={columns}
                            data={sortedDevices}
                        />
                    </div>
                </Card>
            </Container>
        </>
    );
};

export default Software;
