import {useEffect, useState} from 'react';
import {Card, Col, Container, FormLabel, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import type {TableColumn} from 'react-data-table-component';
import DataTable from 'react-data-table-component';
import {useQuery} from 'react-query';
import useApiFetch from '@/Utils/useApiFetch';

type KandjiVolume = {
    'name' : string;
    'format' : string;
    'percentUsed' : string;
    'identifier' : string;
    'capacity' : string;
    'available' : string;
    'encrypted' : 'No' | 'Yes';
};

type DeviceDetails = {
    'General' : {
        'deviceId' : string;
        'deviceName' : string;
        'lastEnrollment' : string;
        'firstEnrollment' : string;
        'model' : string;
        'platform' : 'Mac' | 'iPad' | 'iPhone' | 'AppleTV';
        'osVersion' : string;
        'systemVersion' : string;
        'bootVolume' : string;
        'timeSinceBoot' : string;
        'lastUser' : string;
        'assetTag' : string;
        'assignedUser' : {
            'email' ?: string;
            'name' ?: string;
            'id' ?: number;
            'isArchived' ?: boolean;
        };
        'blueprintName' : string;
        'blueprintUuid' : string;
    };
    'mdm' : {
        'mdmEnabled' : 'True' | 'False';
        'installDate' : string;
        'lastCheckIn' : string;
        'mdmEnabledUser' : string[];
    };
    'activationLock' : {
        'bypassCodeFailed' : boolean;
        'userActivationLockEnabled' : boolean;
        'deviceActivationLockEnabled' : boolean;
        'activationLockAllowedWhileSupervised' : boolean;
        'activationLockSupported' : boolean;
    };
    'filevault' : {
        'filevaultEnabled' : boolean;
        'filevaultRecoverykeyType' : string;
        'filevaultPrkEscrowed' : boolean;
        'filevaultNextRotation' : string;
        'filevaultRegenRequired' : boolean;
    };
    'automatedDeviceEnrollment' : {
        'autoEnrollEligible' ?: boolean;
        'autoEnrolled' ?: boolean;
    };
    'kandjiAgent' : {
        'agentInstalled' : 'True' | 'False';
        'installDate' : string;
        'lastCheckIn' : string;
        'agentVersion' : string;
    };
    'hardwareOverview' : {
        'modelName' ?: string;
        'modelIdentifier' ?: string;
        'processorName' ?: string;
        'processorSpeed' ?: string;
        'numberOfProcessors' ?: string;
        'totalNumberOfCores' ?: string;
        'memory' ?: string;
        'udid' ?: string;
        'serialNumber' : string;
    };
    'volumes' : KandjiVolume[];
    'network' : {
        'localHostname' : string;
        'macAddress' : string;
        'ipAddress' : string;
        'publicIp' : string;
    };
    'recoveryInformation' : {
        'recoveryLockEnabled' : boolean;
        'firmwarePasswordExist' : boolean;
        'firmwarePasswordPending' : boolean;
        'passwordRotationScheduled' ?: string | null;
    };
    'users' : {
        regularUsers : Array<{
            username : string;
            uid : string;
            path : string;
            admin : 'Yes' | 'No';
            Name ?: string;
        }>;
        systemUsers : Array<{
            username : string;
            uid : string;
            path : string;
            admin : 'Yes' | 'No';
        }>;
    };
    installedProfiles : Array<{
        name : string;
        uuid : string;
        verified : string;
        identifier : string;
        organization : string;
        payloadTypes : string[];
        installDate : string;
    }>;
    appleBusinessManager : {
        model ?: string;
        color ?: string;
        description ?: string;
        serialNumber ?: string;
        deviceFamily ?: string;
        os ?: string;
        deviceAssignedDate ?: string;
        deviceAssignedBy ?: string;
    };
    securityInformation : {
        remoteDesktopEnabled : boolean;
    };
};

type CrashPlanComputer = {
    computerId : number;
    name : string;
    osHostname : string;
    guid : string;
    type : string;
    status : string;
    active : boolean;
    blocked : boolean;
    alertState : number;
    alertStates : string[];
    userId : number;
    userUid : string;
    orgId : number;
    orgUid : string;
    computerExtRef ?: null;
    notes ?: null;
    parentComputerId ?: null;
    parentComputerGuid ?: null;
    lastConnected : string;
    osName : string;
    osVersion : string;
    osArch : string;
    address : string;
    remoteAddress : string;
    javaVersion : string;
    modelInfo : string;
    timeZone : string;
    version : number;
    productVersion : string;
    buildVersion : number;
    creationDate : string;
    modificationDate : string;
    loginDate : string;
    service : string;
    backupUsage : [
        {
            targetComputerParentId ?: null;
            targetComputerParentGuid ?: null;
            targetComputerGuid : string;
            targetComputerName : string;
            targetComputerOsName ?: null;
            targetComputerType : string;
            selectedFiles : number;
            selectedBytes : number;
            todoFiles : number;
            todoBytes : number;
            archiveBytes : number;
            billableBytes : number;
            sendRateAverage : number;
            completionRateAverage : number;
            lastBackup : string;
            lastCompletedBackup : string;
            lastConnected : string;
            lastMaintenanceDate ?: null;
            lastCompactDate ?: null;
            modificationDate ?: null;
            creationDate ?: null;
            using : boolean;
            alertState : number;
            alertStates : string[];
            percentComplete : number;
            storePointId ?: null;
            storePointName ?: null;
            serverId ?: null;
            serverGuid : string;
            serverName ?: null;
            serverHostName ?: null;
            isProvider : boolean;
            archiveGuid ?: null;
            archiveFormat : string;
            activity : {
                connected : boolean;
                backingUp : boolean;
                restoring : boolean;
                timeRemainingInMs : number;
                remainingFiles : number;
                remainingBytes : number;
            };
        },
    ];
};

type CombinedDevice = {
    kandji : DeviceDetails;
    crashplan ?: CrashPlanComputer;
    bootVolume ?: KandjiVolume;
};

const ALL = 'All';
const HAS = 'Has';
const HASNOT = 'Has_not';

const columns : Array<TableColumn<CombinedDevice>> = [
    {
        name: 'Device Name',
        selector: (row : CombinedDevice) => row.kandji.General.deviceName,
        sortable: true,
    },
    {
        name: 'OS Version',
        selector: (row : CombinedDevice) => row.kandji.General.osVersion,
        sortable: true,
    },
    {
        name: 'Serial',
        selector: (row : CombinedDevice) => row.kandji.hardwareOverview.serialNumber,
        sortable: true,
        format: row => {
            return <a
                target="_blank"
                href={`https://soliantconsulting.kandji.io/devices/${row.kandji.General.deviceId}/techSpecs`}
                rel="noreferrer">
                {row.kandji.hardwareOverview.serialNumber}
            </a>;
        },
        conditionalCellStyles: [{
            when: r => {
                return r.kandji.General.osVersion === 'Employee';
            },
            classNames: ['table-success'],
        }, {
            when: r => {
                return r.kandji.hardwareOverview.serialNumber === 'Subcontractor';
            },
            classNames: ['table-warning'],
        }, {
            when: r => {
                return r.kandji.General.deviceName === 'bunnies';
            },
            classNames: ['table-danger'],
        }],
    },
    {
        name: 'Last Checkin',
        selector: (row : CombinedDevice) => row.kandji.mdm.lastCheckIn,
        format: (row : CombinedDevice) => (new Date(row.kandji.mdm.lastCheckIn)).toLocaleDateString(),
        sortable: true,
    },
    {
        name: 'Last User',
        selector: (row : CombinedDevice) => row.kandji.General.lastUser,
        format: row => {
            return <div title={`Assigned user: ${row.kandji.General.assignedUser.email ?? ''}`}>
                {row.kandji.General.lastUser}
            </div>;
        },
        sortable: true,
        conditionalCellStyles: [{
            when: r => {
                const email = r.kandji.General.assignedUser.email ?? '';
                const assignedUser = email.split('@')[0];
                return assignedUser !== r.kandji.General.lastUser
                    && r.kandji.General.lastUser !== 'root';
            },
            classNames: ['table-warning'],
        }],
    },
    {
        name: 'Memory',
        selector: (row : CombinedDevice) => {
            const memorySplit = (row.kandji.hardwareOverview.memory ?? '').split('GB');
            return memorySplit.length > 1 ? memorySplit[0] + ' GB' : memorySplit[0];
        },
        sortable: true,
    },
    {
        name: 'Processor',
        selector: (row : CombinedDevice) => {
            const cpuSplit = (row.kandji.hardwareOverview.processorName ?? '').split('Intel');
            return cpuSplit.length > 1 ? 'Intel' + cpuSplit[1] : cpuSplit[0];
        },
        sortable: true,
    },
    {
        name: 'Model',
        selector: (row : CombinedDevice) => row.kandji.General.model.replace('MacBook Pro', 'MBP'),
        sortable: true,
        grow: 2,
    },
    {
        name: 'Volume',
        sortable: true,
        selector: row => {
            if (!row.bootVolume) {
                return '';
            }

            return parseInt(row.bootVolume.percentUsed, 10);
        },
        format: row => row.bootVolume?.percentUsed ?? '',
        conditionalCellStyles: [{
            when: r => {
                if (r.bootVolume === undefined) {
                    return false;
                }

                const precentUsed = parseInt(r.bootVolume.percentUsed, 10);
                return precentUsed >= 70 && precentUsed < 90;
            },
            classNames: ['table-warning'],
        }, {
            when: r => {
                return r.bootVolume !== undefined && parseInt(r.bootVolume.percentUsed, 10) >= 90;
            },
            classNames: ['table-danger'],
        }],
    },
    {
        name: 'abm',
        selector: (row : CombinedDevice) => row.kandji.appleBusinessManager.model === undefined ? 'No' : 'Yes',
        sortable: true,
    },
    {
        name: 'backup%',
        selector: (row : CombinedDevice) => row.crashplan?.backupUsage[0].percentComplete ?? '',
        format: row => {
            if (!row.crashplan) {
                return '';
            }

            return <a
                target="_blank"
                href={`https://console.us2.crashplan.com/app/#/console/device/${row.crashplan.computerId}`}
                rel="noreferrer">
                {row.crashplan.backupUsage[0].percentComplete}%
            </a>;
        },
        sortable: true,
        conditionalCellStyles: [{
            when: r => {
                return r.crashplan !== undefined && r.crashplan.backupUsage[0].percentComplete > 80
                    && r.crashplan.backupUsage[0].percentComplete < 99;
            },
            classNames: ['table-warning'],
        }, {
            when: r => {
                return r.crashplan === undefined || r.crashplan.backupUsage[0].percentComplete <= 80;
            },
            classNames: ['table-danger'],
        }],
    },
];

const Devices = () : JSX.Element => {
    const [sortedDevices, setSortedDevices] = useState<CombinedDevice[]>([]);
    const [osFilter, setOsFilter] = useState<string | undefined>(ALL);
    const [memoryFilter, setMemoryFilter] = useState<string | undefined>(ALL);
    const [modelFilter, setModelFilter] = useState<string | undefined>(ALL);
    const [abmFilter, setAbmFilter] = useState<string | undefined>(ALL);
    const [osList, setOsList] = useState<string[]>([]);
    const [modelList, setModelList] = useState<string[]>([]);

    const apiFetch = useApiFetch();

    const {isLoading: devicesLoading, error: devicesError, data: devices} = useQuery<DeviceDetails[]>(
        'deviceDetailsData',
        async () => {
            const res = await apiFetch('/v1/devices/details');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await res.json();
        }
    );
    const {isLoading: crashplanLoading, error: crashPlanError, data: crashPlanDevices}
        = useQuery<CrashPlanComputer[]>('crashplan-devices', async () => {
            const res = await apiFetch('/v1/crashplan-devices/');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await res.json();
        });

    useEffect(() => {
        if (devices && crashPlanDevices) {
            const tmpDevices : CombinedDevice[] = [];
            const allOs : string[] = [];
            const allModel : string[] = [];

            for (const device of devices) {
                if (!allModel.includes(device.General.model)) {
                    allModel.push(device.General.model);
                }

                if (!allOs.includes(device.General.osVersion)) {
                    allOs.push(device.General.osVersion);
                }

                tmpDevices.push({
                    kandji: device,
                    crashplan: crashPlanDevices.find(cd => cd.osHostname === device.General.deviceName
                        || cd.name === device.General.deviceName),
                    bootVolume: device.volumes.find(v => v.name === device.General.bootVolume),
                });
            }

            // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
            setOsList(allOs.sort().reverse());
            // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
            setModelList(allModel.sort().reverse());
            setSortedDevices(tmpDevices);
        } else {
            setSortedDevices([]);
        }
    }, [devices, crashPlanDevices]);

    if (devicesError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching the kandji devices {JSON.stringify(devicesError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (crashPlanError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching the crashplan devices {JSON.stringify(crashPlanError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (devicesLoading || !devices || crashplanLoading || !crashPlanDevices) {
        return <>
            <Container>
                <Row>
                    <Col className="mx-auto">
                        <h1>Loading</h1>
                        <div className="spinner-border" role="status">
                        </div>
                    </Col>
                </Row>
            </Container>
        </>;
    }

    return (
        <>
            <Container fluid={true}>
                <Card className="mt-2">
                    <div className="card-body">
                        <h4>Device List</h4>
                        <Row>
                            <Col xs m={4}>
                                <FormLabel htmlFor="app">OS Version</FormLabel>
                                <Form.Control
                                    type='select'
                                    as="select"
                                    name='app'
                                    id='app'
                                    className='mb-2'
                                    value={osFilter}
                                    onChange={event => {
                                        setOsFilter(event.target.value);
                                    }}
                                >
                                    <option value={ALL}>All</option>
                                    {osList.map(o => {
                                        return <option key={`os-version-${o}`} value={o}>{o}</option>;
                                    })}
                                </Form.Control>
                            </Col>
                            <Col xs m={4}>
                                <FormLabel htmlFor="app">Memory</FormLabel>
                                <Form.Control
                                    type='select'
                                    as="select"
                                    name='app'
                                    id='app'
                                    className='mb-2'
                                    value={memoryFilter}
                                    onChange={event => {
                                        setMemoryFilter(event.target.value);
                                    }}
                                >
                                    <option value={ALL}>All</option>
                                    <option value='16'>16GB</option>
                                    <option value='32'>32GB</option>
                                    <option value='64'>64GB</option>
                                </Form.Control>
                            </Col>
                            <Col xs m={4}>
                                <FormLabel htmlFor="app">Model</FormLabel>
                                <Form.Control
                                    type='select'
                                    as="select"
                                    name='app'
                                    id='app'
                                    className='mb-2'
                                    value={modelFilter}
                                    onChange={event => {
                                        setModelFilter(event.target.value);
                                    }}
                                >
                                    <option value={ALL}>All</option>
                                    {modelList.map(o => {
                                        return <option key={`model-${o}`} value={o}>{o}</option>;
                                    })}
                                </Form.Control>
                            </Col>
                            <Col xs m={4}>
                                <FormLabel htmlFor="app">ABM</FormLabel>
                                <Form.Control
                                    type='select'
                                    as="select"
                                    name='app'
                                    id='app'
                                    className='mb-2'
                                    value={abmFilter}
                                    onChange={event => {
                                        setAbmFilter(event.target.value);
                                    }}
                                >
                                    <option value={ALL}>All</option>
                                    <option value={HAS}>Yes</option>
                                    <option value={HASNOT}>No</option>
                                </Form.Control>
                            </Col>
                        </Row>
                    </div>
                </Card>
                <DataTable
                    columns={columns}
                    data={sortedDevices.filter(d => {
                        if (osFilter !== ALL) {
                            if (d.kandji.General.osVersion !== osFilter) {
                                return false;
                            }
                            //d.kandji.hardwareOverview.memory?.indexOf(osFilter)
                        }

                        if (memoryFilter !== ALL && typeof memoryFilter === 'string') {
                            const memory = d.kandji.hardwareOverview.memory;

                            if (!memory || !memory.includes(memoryFilter)) {
                                return false;
                            }
                        }

                        if (modelFilter !== ALL && typeof modelFilter === 'string') {
                            if (d.kandji.General.model !== modelFilter) {
                                return false;
                            }
                        }

                        if (abmFilter === HAS) {
                            if (d.kandji.appleBusinessManager.model === undefined) {
                                return false;
                            }
                        } else if (abmFilter === HASNOT) {
                            if (d.kandji.appleBusinessManager.model !== undefined) {
                                return false;
                            }
                        }

                        return true;
                    })}/>
            </Container>
        </>
    );
};

export default Devices;
