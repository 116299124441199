import {zodResolver} from '@hookform/resolvers/zod';
import {useCallback, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';
import type {MacVersionsApi} from '@/pages/MacVersions';
import useApiFetch from '@/Utils/useApiFetch';
import {errorMap} from '@/Utils/zod';

const versionSchema = z.object({
    majorVersion: z.number().min(11).max(100),
    minVersion: z.string(),
    enforceDate: z.coerce.date(),
});

export type VersionFieldValues = z.infer<typeof versionSchema>;

type Props = {
    version : MacVersionsApi;
    refresh : () => Promise<void>;
};

const VersionForm = ({version, refresh} : Props) : JSX.Element => {
    const [saving, setSaving] = useState<boolean>(false);
    const apiFetch = useApiFetch();
    const form = useForm<VersionFieldValues>({
        resolver: zodResolver(versionSchema, {errorMap}),
        defaultValues: {
            ...version,
            enforceDate: new Date(version.enforceDate),
        },
    });

    const handleSubmit = useCallback(async (values : VersionFieldValues) => {
        console.log('Values', {
            ...values,
            enforceDate: values.enforceDate.toISOString(),
        });
        setSaving(true);

        try {
            await apiFetch('/v1/macos-versions', {
                method: 'POST',
                body: JSON.stringify({
                    ...values,
                    enforceDate: values.enforceDate.toISOString(),
                }),
            });
            await refresh();
        } catch (e) {
            console.error('error saving', e);
        }

        setSaving(false);
    }, []);

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Row className="mt-4 px-2">
                <Form.Group className="col-md-3" controlId="formUsername">
                    <Controller control={form.control} name="majorVersion"
                        defaultValue={13}
                        render={({field: {onChange, value, ref}}) => (
                            <Form.Control onChange={onChange} value={value} ref={ref}
                                isInvalid={form.formState.errors.majorVersion !== undefined}
                                placeholder="Major version like 13"/>)}/>
                    <Form.Control.Feedback type="invalid">
                        {form.formState.errors.majorVersion?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-3" controlId="formUsername">
                    <Controller control={form.control} name="minVersion"
                        defaultValue=""
                        render={({field: {onChange, value, ref}}) => (
                            <Form.Control onChange={onChange} value={value} ref={ref}
                                isInvalid={form.formState.errors.minVersion !== undefined}
                                placeholder="Min version like 13.3.1"/>)}/>
                    <Form.Control.Feedback type="invalid">
                        {form.formState.errors.minVersion?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-3" controlId="enforceDate">
                    <Controller control={form.control} name="enforceDate"
                        defaultValue={new Date()}
                        render={({field: {onChange, value, ref}}) => (
                            <Form.Control
                                type="date"
                                onChange={onChange}
                                value={typeof value === 'string' ? value : value.toISOString().split('T')[0]}
                                ref={ref}
                                isInvalid={form.formState.errors.enforceDate !== undefined}
                            />)}/>
                    <Form.Control.Feedback type="invalid">
                        {form.formState.errors.enforceDate?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Col className="col-md-3">
                    <Button type="submit" variant="contained" disabled={saving}>
                        {saving ? 'Saving...' : 'Save'}
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default VersionForm;
