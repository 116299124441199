import {useEffect, useState} from 'react';
import {Card, Col, Container, Row, FormGroup, FormLabel} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {useQuery} from 'react-query';
import DeviceNotes from '@/components/DeviceNotes';
import useApiFetch from '@/Utils/useApiFetch';

type User = {
    email ?: string;
    name ?: string;
    id ?: number;
    isArchived ?: boolean;
};

type ListDevice = {
    'deviceId' : string;
    'deviceName' : string;
    'model' : string;
    'serialNumber' : string;
    'platform' : 'Mac' | 'iPad' | 'iPhone' | 'AppleTV';
    'osVersion' : string;
    'lastCheckIn' : string;
    'user' : User;
    'assetTag' : string;
    'blueprintId' : string;
    'mdmEnabled' : boolean;
    'agentInstalled' : boolean;
    'isMissing' : boolean;
    'isRemoved' : boolean;
    'agentVersion' : string;
    'firstEnrollment' : string;
    'lastEnrollment' : string;
    'blueprintName' : string;
};

const Notes = () : JSX.Element => {
    const [selectedUser, setSelectedUser] = useState<string>('');
    const [users, setUsers] = useState<User[]>([]);
    const apiFetch = useApiFetch();

    const {isLoading: devicesLoading, error: devicesError, data: devices} = useQuery<ListDevice[]>(
        'deviceData',
        async () => {
            const res = await apiFetch('/v1/devices');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return await res.json();
        }
    );

    useEffect(() => {
        if (devices?.length && devices.length > 0) {
            const users : User[] = [];

            for (const device of devices) {
                if (device.user.email && !users.find(u => u.email === device.user.email)) {
                    users.push(device.user);
                }
            }

            if (users.length > 0 && users[0].email) {
                setSelectedUser(users[0].email);
            } else {
                setSelectedUser('');
            }

            setUsers(users);
        }
    }, [devices]);

    if (devicesError) {
        return <Container>
            <Row>
                <Col className="col-md-6">
                    <Card className="alert alert-danger">
                        <div className="card-body">
                            There was an error fetching the devices {JSON.stringify(devicesError)}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }

    if (devicesLoading || !users.length || !devices) {
        return <>
            <Container>
                <Row>
                    <Col className="mx-auto">
                        <h1>Loading</h1>
                        <div className="spinner-border" role="status">
                        </div>
                    </Col>
                </Row>
            </Container>
        </>;
    }

    return (
        <>
            <Container>
                <Row className="mt-4 mb-3 py-4 px-2">
                    <Col>
                        <FormGroup>
                            <FormLabel htmlFor="app">Select User</FormLabel>
                            <Form.Control
                                type='select'
                                as="select"
                                name='app'
                                id='app'
                                value={selectedUser}
                                onChange={event => {
                                    setSelectedUser(event.target.value);
                                }}
                            >
                                {users.map(a => {
                                    return <option
                                        value={a.email}
                                        key={`option${a.email ?? ''}`}
                                    >{a.email} - {a.name}</option>;
                                })}
                            </Form.Control>
                        </FormGroup>
                    </Col>
                </Row>
                {selectedUser
                    && <Card className="mt-2">
                        <div className="card-body">
                            <Row>
                                <Col>
                                    <h4>Devices and Notes for {selectedUser}</h4>
                                    {devices.filter(d => d.user.email === selectedUser)
                                        .map(d => {
                                            return <div key={d.deviceId}>
                                                <Row><Col><b>Serial:</b> {d.serialNumber}</Col></Row>
                                                <Row><Col><b>Computer Name:</b> {d.deviceName}</Col></Row>
                                                <Row><Col><b>Model:</b> {d.model}</Col></Row>
                                                <Row><Col>
                                                    <b>Kandji Last Seen:</b> {new Date(d.lastCheckIn).toLocaleString()}
                                                </Col></Row>
                                                <DeviceNotes

                                                    deviceId={d.deviceId}
                                                />
                                                <hr/>
                                            </div>;
                                        })
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Card>
                }
            </Container>
        </>
    );
};

export default Notes;
