import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Layout from './Layout';
import Devices from './pages/Devices';
import Notes from './pages/Notes';
import Software from './pages/Software';
import MsalProvider from '@/components/Auth/Msal';
import MacVersions from '@/pages/MacVersions';
import Users from '@/pages/Users';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

const App = () : JSX.Element => {
    return (
        <QueryClientProvider client={queryClient}>
            <MsalProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout/>}>
                            <Route index element={<Software/>}/>
                            <Route path="notes" element={<Notes/>}/>
                            <Route path="software" element={<Software/>}/>
                            <Route path="devices" element={<Devices/>}/>
                            <Route path="users" element={<Users/>}/>
                            <Route path="macversions" element={<MacVersions/>}/>
                            <Route path="*" element={<span>404</span>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </MsalProvider>
        </QueryClientProvider>
    );
};

export default App;
